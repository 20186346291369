import Button from '@mui/material/Button';
import style from '../componentes/confirmacao.module.css'
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/material';


export default function Confirmacao({ valor, setEstado }) {
  return (
    <div className={style.container}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Typography variant="h5">Revise e confirme a operação</Typography>
        <Divider variant="middle" />
        <Typography className={style.texto} variant="body1" color="text.secondary">
          Você está fazendo um pix de
        </Typography>
        <Typography variant="h3" className={style.preco}>R$ {valor}</Typography>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            color="error"
            onClick={() => setEstado("calculadora")}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            startIcon={<CheckCircleIcon />}
            color="success"
            onClick={() => setEstado("qrcode")}
          >
            Confirmar
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
