import Typography from '@mui/material/Typography';
import style from '../componentes/sucesso.module.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

export default function Sucesso({ valor, nome, setEstado }) {
    return (
        <Stack className={style.container}
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            >
            <CheckCircleIcon sx={{ fontSize: 200, color: '#ffffff' }} />
            <Typography color="#ffffff" variant="h5">Pix recebido de:</Typography>
            <Typography color="#ffffff" variant="h5">{nome}</Typography>
            <Typography color="#ffffff"  variant="h3">R$ {valor}</Typography>
            <Button variant="contained"  color="error"
                startIcon={<CloseIcon />}
                onClick={() => setEstado('calculadora')}>Finalizar</Button>
        </Stack>
    )
}