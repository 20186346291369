import { LogLevel } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_CLIENT_ID;
const tenantName = 'deflagrapix';

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_SUSI"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/B2C_1_SUSI`
        }
    },
    authorityDomain: `${tenantName}.b2clogin.com`
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const authScopes = {
    scopes: [process.env.REACT_APP_ESCOPO_PIX_API, "openid"]
};
