import style from '../componentes/maquina.module.css'
import Paper from '@mui/material/Paper';
import Calculadora from './calculadora';
import { useEffect, useState } from 'react';
import Confirmacao from './confirmacao';
import Sucesso from './sucesso';
import Qrcode from './qrcode';
import TelaDeErro from './tela-de-erro';

export default function Maquina() {
    const [valorPix, setValorPix] = useState('');
    const [nome, setNome] = useState('');
    const [estado, setEstado] = useState('calculadora');

    useEffect(() => {
        renderiza(estado);
    }, [estado]);

    function renderiza(estadoAtual) {
        switch (estadoAtual) {
            case 'confirmacao':
                return <Confirmacao valor={valorPix} setEstado={setEstado}></Confirmacao>
            case 'mensagem-sucesso':
                return <Sucesso valor={valorPix} nome={nome} setEstado={setEstado}></Sucesso>
            case 'qrcode':
                return <Qrcode valor={valorPix} setNome={setNome} setEstado={setEstado}></Qrcode>
            case 'erro':
                return <TelaDeErro setEstado={setEstado}></TelaDeErro>
            default:
                return <Calculadora setValorPix={setValorPix} setEstado={setEstado}></Calculadora>
        }
    }

    return (
        <Paper className={style.maquina} elevation={8}>
            {renderiza(estado)}
        </Paper>
    );
}