import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import Barra from "./componentes/barra";
import Login from "./componentes/login";
import Maquina from "./componentes/maquina";
import { authScopes } from "./authConfig";

function App({ pca }) {

  async function handleLogin() {
    await pca.handleRedirectPromise();

    const accounts = pca.getAllAccounts();
    if (accounts.length === 0)
      await pca.loginRedirect(authScopes).catch((error) => console.log(error));
  }
  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <Barra></Barra>
        <center>
          <Maquina></Maquina>
        </center>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login acao={handleLogin}></Login>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
