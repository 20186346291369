import Typography from '@mui/material/Typography';
import style from '../componentes/tela-de-erro.module.css'
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

export default function TelaDeErro({ setEstado }) {
  return (
    <Stack className={style.container}
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}>
      <Typography color="#ffffff" variant="h5">
        A operação foi cancelada
      </Typography>
      <CancelIcon
        className={style.botao}
        sx={{ fontSize: 200, color: "#ffffff" }}
      />
      <Typography
        className={style.nome}
        color="#ffffff"
        variant="h5"
        sx={{ padding: "24px" }}
      >
        O tempo para realizar a transferência esgotou!
      </Typography>
      <Button variant="contained" startIcon={<CloseIcon />}
        sx={{ color: '#bb0b1e', backgroundColor: '#ffffff' }}
        onClick={() => setEstado('calculadora')}>Finalizar</Button>
    </Stack>
  );
}

