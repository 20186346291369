import { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { Divider } from "@mui/material";
import { authScopes } from "../authConfig";

export default function Barra() {
    const [detalhesConta, setDetalhesConta] = useState(null);
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    async function handleLogout() {
        await instance.logoutRedirect(authScopes).catch((error) => console.log(error));
    }

    useEffect(() => {
        setDetalhesConta({ nome: instance.getAllAccounts()[0]?.name })
    }, [isAuthenticated]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    {/* <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        PedPix
                    </Typography>
                    <Stack spacing={2} direction="row" alignItems="center"
                        divider={<Divider orientation="vertical" flexItem />}>
                        {detalhesConta && (
                            <Typography variant="subtitle1">{detalhesConta.nome}</Typography>
                        )}
                        <Typography variant="caption" onClick={() => handleLogout()}>Sair</Typography>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Box>
    );
}