import { Button } from '@mui/material';
import style from '../componentes/botao.module.css'

export default function Botao({numero, icone, cor, aoClicar, link}) {
    const padding = icone?'0': '5 15';
    const estilo = {
        padding: padding
    }
    return (
    <Button onClick={aoClicar}
    variant="outlined"
    startIcon={icone} 
    color={cor}
    style={estilo}
    href={link}
    className={style.botao}>
        {numero}
        </Button>
    );
}