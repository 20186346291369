import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import logo from "../logo_apil.png";

export default function Login({ acao }) {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ width: 240, height: 240 }} src={logo} variant="rounded" />
        <Typography component="h1" variant="h5">
          PedPIX
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => acao()}
          >
            Fazer login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
