import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import './styles/globals.css'


export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <App pca={msalInstance} />
  </ThemeProvider>
);