import Typography from '@mui/material/Typography'
import style from '../componentes/qrcode.module.css'
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from "react-qr-code";
import { Stack } from '@mui/material';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useMsal } from '@azure/msal-react';
import CircularProgress from '@mui/material/CircularProgress';
import { InteractionRequiredAuthError } from '@azure/msal-browser';




export default function Qrcode({ valor, setNome, setEstado }) {
  const [contador, setContador] = useState(180);
  const [connection, setConnection] = useState(null);
  const [retornoWebhook, _setRetornoWebhook] = useState(null);
  const { instance, accounts } = useMsal();
  const identificador = accounts[0]?.idTokenClaims.city;
  const retornoWebhookRef = useRef(retornoWebhook);
  const setRetornoWebhook = data => {
    retornoWebhookRef.current = data;
    _setRetornoWebhook(data);
  };


  useEffect(() => {
    contador > 0 && setTimeout(() => setContador(contador - 1), 1000);
    if (contador === 0)
      cancelar();
  }, [contador]);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_URL_PIX_WEBHOOK)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    renderizar();
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          console.log('Connected!');
          connection.on('recebimento-pix', (txid, chave, valor, nomePagador) => {
            if (retornoWebhookRef.current.id == txid) {
              setNome(nomePagador);
              setEstado('mensagem-sucesso');
            }
          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection]);

  function cancelar() {
    setEstado('erro');
  }

  function solicitarPix(token) {
    fetch(process.env.REACT_APP_URL_PIX_API + identificador, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_SUBS_KEY,
      },
      body: JSON.stringify({ valor: Number(valor.replace(",", ".")) }),
    })
      .then((resposta) => resposta.json())
      .then((dados) => {
        console.log("txid: " + dados.id);
        setRetornoWebhook(dados);
      });
  }

  function renderizarQrcode() {
    if (retornoWebhook) {
      return (
        <QRCode
          value={retornoWebhook?.qrcode || ""}
          size={500}
          style={{
            height: "auto",
            maxWidth: "100%",
            width: "100%",
            flex: "1",
            margin: "0",
          }}
          viewBox={`0 0 500 500`}
        />
      );
    }

    return <CircularProgress className={style.loading} />;
  }

  function renderizar() {
    const accessTokenRequest = {
      scopes: [process.env.REACT_APP_ESCOPO_PIX_API, "openid"],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        solicitarPix(accessTokenResponse.idToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenRedirect(accessTokenRequest)
            .then(function (accessTokenResponse) {
              solicitarPix(accessTokenResponse.idToken);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  }
  
  return (
    <Stack className={style.qrcode}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography className={style.titulo} variant="h5">
          Pix no valor de
        </Typography>
        <Typography variant="h5" sx={{ padding: "16px" }} color="#3676a1">
          R$ {valor}
        </Typography>
      </Stack>
      {renderizarQrcode()}
      <Typography
        variant="h6"
        sx={{ padding: "10px", margin: "25px 0px" }}
        className={style.segundos}
      >
        {contador} segundos restantes...
      </Typography>
      <Button
        variant="contained"
        startIcon={<CloseIcon />}
        color="error"
        onClick={() => setEstado('calculadora')}
      >
        Cancelar
      </Button>
    </Stack>
  );
}