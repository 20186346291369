import style from '../componentes/calculadora.module.css'
import Botao from './botao';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Paper } from '@mui/material';


export default function Calculadora({ setValorPix, setEstado }) {
  const [valor, setValor] = useState([]);
  const [valorVisor, setValorVisor] = useState("0,00");

  function cliqueNumero(numero) {
    valor.push(numero);
    setValor(valor);
    formatarValorVisor(valor.toString().replaceAll(',', ''));
  }
  function cliqueApagar() {
    valor.pop();
    setValor(valor);
    formatarValorVisor(valor.toString().replaceAll(',', ''));
  }

  function formatReal(int) {
    var tmp = int + '';
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6)
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  function formatarValorVisor(valor) {
    if (valor.length === 0) {
      valor = '000' + valor;
    } else if (valor.length === 1) {
      valor = '00' + valor;
    } else if (valor.length === 2) {
      valor = '0' + valor;
    }
    valor = formatReal(valor);
    setValorVisor(valor);
  }

  function confirmar() {
    setValorPix(valorVisor);
    setEstado('confirmacao');
  }

  return (
    <div className={style.calculadora}>
      <Typography className={style.titulo} variant="h4" gutterBottom>Qual valor do pix?</Typography>
      <Paper className={style.visor} variant="outlined">
        <Typography variant="h4" gutterBottom >R$</Typography>
        <Typography variant="h2" gutterBottom >{valorVisor}</Typography>
      </Paper>
      <div className={style.linha}>
        <Botao numero={1} aoClicar={() => cliqueNumero(1)}></Botao>
        <Botao numero={2} aoClicar={() => cliqueNumero(2)}></Botao>
        <Botao numero={3} aoClicar={() => cliqueNumero(3)}></Botao>
      </div>
      <div className={style.linha}>
        <Botao numero={4} aoClicar={() => cliqueNumero(4)}></Botao>
        <Botao numero={5} aoClicar={() => cliqueNumero(5)}></Botao>
        <Botao numero={6} aoClicar={() => cliqueNumero(6)}></Botao>
      </div>
      <div className={style.linha}>
        <Botao numero={7} aoClicar={() => cliqueNumero(7)}></Botao>
        <Botao numero={8} aoClicar={() => cliqueNumero(8)}></Botao>
        <Botao numero={9} aoClicar={() => cliqueNumero(9)}></Botao>
      </div>
      <div className={style.linha}>
        <Botao icone={<CheckCircleIcon />} cor="success" aoClicar={() => confirmar()}></Botao>
        <Botao numero={0} aoClicar={() => cliqueNumero(0)}></Botao>
        <Botao icone={<BackspaceIcon />} cor="error" aoClicar={() => cliqueApagar()}></Botao>
      </div>
    </div>
  );
}